import { FormattedDate, FormattedMessage } from 'react-intl';
import { searchSubscriptionLastPurchased } from '../../util/api';
import dateFormatOptions from '../../util/dateFormatOptions';
import css from './LastPurchasedBadge.module.scss';
import { useEffect, useState } from 'react';
import ListingTypeEnum from '../../enums/listing-type.enum';
import { useSelector } from 'react-redux';

const LastPurchasedBadge = props => {
  const state = useSelector(state => state);
  const { isAuthenticated } = state.auth;

  if (!isAuthenticated) {
    return null;
  }

  const { listing } = props;
  const listingType = listing?.attributes?.publicData?.listingType;
  if (listingType !== ListingTypeEnum.PackageSubscriptionDefaultPurchase) {
    return null;
  }

  const [lastPurchasedDate, setLastPurchasedDate] = useState();

  useEffect(() => {
    searchSubscriptionLastPurchased({
      listingId: listing.id.uuid,
    }).then(res => {
      if (res && res.startDate) {
        setLastPurchasedDate(res.startDate);
      }
    });
  }, []);

  return lastPurchasedDate ? (
    <div className={css.lastPurchasedContainer}>
      <div className={css.rowUnsetMarginLR}>
        <div className={css.col12}>
          <span className={css.badge}>
            <FormattedMessage id="LastPurchasedBadge.message" />{' '}
            <span className={css.date}>
              <FormattedDate value={lastPurchasedDate} {...dateFormatOptions} />
            </span>
          </span>
        </div>
      </div>
    </div>
  ) : null;
};

export default LastPurchasedBadge;
